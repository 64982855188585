exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-onboarding-tsx": () => import("./../../../src/pages/onboarding.tsx" /* webpackChunkName: "component---src-pages-onboarding-tsx" */),
  "component---src-pages-properties-grev-55-tsx": () => import("./../../../src/pages/properties/grev55.tsx" /* webpackChunkName: "component---src-pages-properties-grev-55-tsx" */),
  "component---src-pages-properties-index-tsx": () => import("./../../../src/pages/properties/index.tsx" /* webpackChunkName: "component---src-pages-properties-index-tsx" */),
  "component---src-pages-properties-market-tsx": () => import("./../../../src/pages/properties/market.tsx" /* webpackChunkName: "component---src-pages-properties-market-tsx" */),
  "component---src-pages-properties-roadmap-tsx": () => import("./../../../src/pages/properties/roadmap.tsx" /* webpackChunkName: "component---src-pages-properties-roadmap-tsx" */),
  "component---src-pages-properties-tokenomics-tsx": () => import("./../../../src/pages/properties/tokenomics.tsx" /* webpackChunkName: "component---src-pages-properties-tokenomics-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */)
}

